import React from 'react'
import { graphql, navigate, withPrefix } from 'gatsby'
// @ts-ignore
import { getUserLangKey } from 'ptz-i18n'
import LocalizedIndex from '../components/localizedIndex/LocalizedIndex'
import { MainPageRawData, SupportedLanguages } from '../types/types'
import { transformPageData } from '../state/helpers'

type IProps = {
  data: MainPageRawData & {
    site: {
      siteMetadata: {
        languages: {
          langs: Array<SupportedLanguages>
          defaultLangKey: SupportedLanguages
        }
      }
    }
  }
}

const IndexPage = ({
  data: {
    site: {
      siteMetadata: {
        languages: { langs, defaultLangKey },
      },
    },
    ...restRawData
  },
}: IProps) => {
  const langKey: string = getUserLangKey(langs, defaultLangKey)
  const homeUrl = withPrefix(`/${langKey}/`)

  if (homeUrl && typeof window !== 'undefined') {
    navigate(homeUrl)
  }

  return <LocalizedIndex locale="es" data={transformPageData(restRawData)} />
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQueryDefault {
    ...PageFragment
  }
`
